import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { DateTime, Interval } from "luxon";
import { css } from "@emotion/react";

import { breakpoints } from "../styles/layouts";
import { NewBadge } from "../components/badge";
import Typography from '../styles/typography';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlay, faCalendar } from '@fortawesome/pro-light-svg-icons';

library.add(faCheck, faPlay, faCalendar);

export const EventCard = ({ event, now }) => {
  const eventStartDate = DateTime.fromISO(event.start.date || event.start.dateTime).setLocale('jp');
  const eventEndDate = DateTime.fromISO(event.end.date || event.end.dateTime).setLocale('jp');
  const createdDate = DateTime.fromISO(event.created).setLocale('jp');
  const eventDuration = Interval.fromDateTimes(eventStartDate, eventEndDate);
  const eventElapsed = Interval.fromDateTimes(eventStartDate, now);

  const [isNewest, setIsNewest] = useState(null);
  const [isEnd, setIsEnd] = useState(null);
  const [isNow, setIsNow] = useState(null);
  const [isBefore, setIsBefore] = useState(null);

  const [elapsedPercent, setElapsedPercent] = useState(null);

  useEffect(() => {
    setIsNewest(createdDate.plus({ days: 30 }) > now);
    setIsEnd(eventEndDate < now);
    setIsNow(eventDuration.contains(now));
    setIsBefore(eventStartDate > now);

    if(eventDuration.contains(now)) {
      setElapsedPercent(Math.round(eventElapsed.count('minutes') / eventDuration.count('minutes') * 100));
    }
  }, [now, eventStartDate, eventEndDate, createdDate, eventDuration, eventElapsed]);

  return (
    <div css={EventItemStyle} data-is-now={isNow} data-is-end={isEnd}>
      <div className="event-status-icon">
        { isEnd && <FontAwesomeIcon className="icon" icon={["fal", "check"]} title="close" /> }
        { isNow && <FontAwesomeIcon className="icon" icon={["fal", "play"]} title="now" /> }
        { isBefore && <FontAwesomeIcon className="icon" icon={["fal", "calendar"]} title="schedule" /> }
      </div>
      <h3 className="event-summary">{event.summary}</h3>
      <p className="event-date">
        { isNewest && <NewBadge top="1rem" right="1rem" />}
        <time dateTime={eventStartDate}>{eventStartDate.toFormat('yyyy年MM月dd日（ccccc）HH:mm')}</time>
        {` ~ `}
        <time dateTime={eventEndDate}>{eventEndDate.toFormat('MM月dd日（ccccc）HH:mm')}</time>
        { isBefore && <span className="event-relative"> {eventStartDate.toRelative()}</span> }
      </p>
      { isNow && <div className="event-sequence">
        <div className="sequence-bar" style={{ width: `${elapsedPercent}%` }} />
      </div> }
    </div>
  );
}

export const LatestEvents = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allLatestCalendarEventsJson(limit: 5) {
        edges {
          node {
            summary
            updated
            created
            start {
              dateTime
              date
            }
            end {
              dateTime
              date
            }
          }
        }
      }
    }
  `);

  const [now, setNow] = useState(DateTime.local());
  const [events, setEvents] = useState([]);
  const { isnow } = props;
  const filterProps = { ...props };

  delete filterProps.isnow;

  useEffect(() => {

    if (isnow) {
      setEvents(data.allLatestCalendarEventsJson.edges.map(({node}) => node).filter(event => {
        const eventStartDate = DateTime.fromISO(event.start.date || event.start.dateTime).setLocale('jp');
        const eventEndDate = DateTime.fromISO(event.end.date || event.end.dateTime).setLocale('jp');
        const eventDuration = Interval.fromDateTimes(eventStartDate, eventEndDate);
        return eventDuration.contains(now);
      }));
    } else {
      setEvents(data.allLatestCalendarEventsJson.edges.map(({node}) => node));
    }

    const intervalId = setInterval(() => {
      setNow(DateTime.local());
    }, 60000);

    return () => {clearInterval(intervalId)};
  }, [now]);

  return (
    <div {...filterProps}>
      <div css={EventListStyle}>
        { events && events.map((event, index) => (
          <EventCard key={index} event={event} now={now} />
        ))}
      </div>
    </div>
  );
}

const EventListStyle = css`
`;

const EventItemStyle = css`
  position: relative;
  overflow: hidden;
  margin-bottom: .5rem;
  padding: 1rem 1.5rem;

  background: #F5F8F9;
  border-radius: 6px;
  box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.24), 0px 1px 4px -1px rgba(0, 0, 0, 0.12), 0px 1px 6px -2px rgba(0, 0, 0, 0.06);

  &:after {
    content: "";
    clear: both;
  }

  &[data-is-end="true"] {
    opacity: 0.5;
  }

  &[data-is-now="true"] {
    background: #fff;

    .event-status-icon {
      color: #E12A52;
    }

    .event-summary {
      color: #1D1E1F;
    }
  }

  .event-status-icon {
    overflow: hidden;
    float: left;
    margin-right: 1rem;
    font-size: 1rem;
  }

  .event-summary {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 2rem;

    ${Typography.p}
  }

  .event-date {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 2rem;

    color: #828485;
  }

  .event-relative {
    display: inline-block;
    margin-left: .75rem;

    color: #4D4F50;

    @media ${breakpoints.console} {
      position: absolute;
      top: 1rem;
      right: 1rem;

      line-height: 1;
    }
  }

  .event-sequence {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;

    background: #eee;

    .sequence-bar {
      content: "";
      height: 3px;

      background: #E12A52;
    }
  }
`;
